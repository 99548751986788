export default {
    lg:'EN',
    loginno:'Login failed, please log in again first',
    cbdx:'Carbon offsetting amount',
	cg:'exceed',
	yh:'user',
	pgb:'Ranking',
	ryxz:'Medal',
	jlmx:'Record details',
	lvsm:'Level Description',
	cbcy:'holdings', //Carbon
	cbcy2:'Carbon asset holdings',
	cbdixiaol:'Carbon asset offsetting',
	cbdixiaol2:'Carbon asset offset records',
	copylink:'Copy link',
	fx:'Share',
	xz:'medal',
	copyok:'Copy Success',
	sys:'Count',
	jsjg:'Count result',
	tpfw:'Your carbon emissions are',
	quxiao:'Cancel',
	cbhx:'Offsetting',
	qtrsj:'Please enter the data',
	more:'more',
	liuyue:'June',
	yinian:'One year',
	wunian:'Five years',
	jiage:'Price',
	click:'Click here',
	sqtgqt:'Apply for custody of other carbon assets',
	sqxtqt:'Apply for trust of other carbon assets',
	zzsqtg:'Applying for hosting',
	zzsqxg:'Applying for trust',
	zanwu:'No data',
	pjno:'Project No',
	pjtype:'Project category',
	pjmc:'Project name',
	pjdate:'Project Year',
	pjid:'Project id',
	num:'number',
	cbnums:'Carbon credit number',
	pjkssj:'Project time-on',
	pjjssj:'Project End Time',
	datepx:'Time sorting',
	shengxu:'ascending order',
	jiangxu:'descending order',
	sqtg:'Apply for carbon custody',
	copylink:'Applying in progress',
	sqxg:'Applying for Carbon Trust',
	sqtips:'After applying, we will contact you within 2 working days. Are you sure you want to apply?',
	tips:'Tips',
	sq:'Apply for',
	reset:'Reset',
	ok:'Determine',
	tj:'Submit',
	ton:'ton',
	shaixuan:'Screen',
	qtxqy:'Please enter the company name',
	qtxpjname:'Please enter the name of the project leader',
	qtxem:'Please enter your email',
	ems:'Email',
	qtxpjid:'Please enter the project ID',
	rzjg:'Carbon Asset Certification Body',
	qtxxmbh:'Please enter the project number',
	qtxpj:'Please enter the project name',
	qtxpjtype:'Please enter the project category',
	qxzrygm:'Please select personnel size',
	qxz:'Please select',
	txynf:'Carbon credit year',
	xmdate:'Select Date',
	qtxxlh:'Please enter the carbon credit serial number',
	qtxnum:'Please fill in the quantity (ton)',
	pjdata:'Project inclusion date',
	kssj:'Start date',
	jsrq:'End date',
	embzq:'Incorrect email format',
	fscode:'SMS sent successfully',
	zsjl:'On sale records',
	xjjl:'Off shelf record',
	xiajias:'Off shelf',
	qrxiajia:'Are you sure to remove it from the shelves?',
	xiajiaok:'Success',
	gmjl:'Record',
	buypj:'Buy',
	buypjname:'Buy project',
	renbuy:'This project has been purchased',
	gjdq:'Country/Region',
	cyz:'Holder',
	buydate:'Year of Buy',
	buynum:'Buy quantity',
	fkprise:'Payment',
	selljg:'Sell price',
	sell:'Sell',
	sellqj:'Sell Price range',
	
	zuidj:'low price',
	zuigj:'high price',
	sellnum:'Sales quantity',
	sellok:'Success',
	
	zyjytips:'Free Trade List',
	czgl:'Operation management',
	sellcb:'Selling carbon assets',
	jyjl:'Transaction records',
	xiangq:'Details',
	upapp1:'Do you want a more complete interactive experience?',
	upapp2:'Visit our mobile page and enjoy a low-carbon lifestyle immediately',
	upapp3:'Scan the code to access the climate store mini program',
	upapp4:'Are you ready for a low-carbon journey?',
	upapp5:'Let reduce the burden on the earth together!',
	upapp6:'Calculate personal carbon emissions',
	upapp7:'Learn one carbon knowledge every day',
	upapp8:'Explore green themes',
	uppwd:'Change password',
	wjpwd:'forgot password',
	area:'Area',
	zhuce:'Register',
	qtxphone:'Please enter your phone number',
	qtxcode:'Please enter the verification code',
	getcode:'Get Code',
	xzarea:'Please select the area code',
	qtxnames:'Please enter your name',
	ydxy:'I have read and agree',
	ydxy2:'Please read the agreement first',
	yszc:'Privacy Policy',
	and:'and',
	fwxy:'Service agreement',
	pwd:'Password',
	login:'Login',
	outlogin:'Log out and log in',
	qtxpwd:'Please enter the password',
	qtxpwd2:'Please enter your password again',
	qtxpwd3:'The passwords entered twice are inconsistent',
	djjr:'Click to enter',
	loading:'The function is under development, please stay tuned',
	backhome:'Back to home',
	backs:'Back',
	mycb:'My Account',
	myinfo:'My Information',
	lxwm:'Contact Us',
	zcok:'Register successful',
	buypr:'Payment amount',
	mybuy:'Balance payment',
	buynotips:'Insufficient Balance',
	myye:'The current balance is',
	zfbbuy:'Alipay payment',
	buybtn:'payment',
	buyno:'Order exception, please try again',
	buytips:'Reminder: After successful payment, there will be no refund. Please operate with caution',
	buyok:'Payment successful',
	buyoktips:'The carbon asset project is expected to arrive within ten minutes, and you will be notified via text message. Please be patient and wait',
	buyfail:'Payment failed',
	sbtips:`
		<div>Please check the following issues</div>
		<div>1.You have cancelled the payment</div>
		<div>2.Your balance is insufficient, please make sure your balance is sufficient</div>
		<div>3.Your payment amount has exceeded the set online payment limit</div>
		<div>4.You have not yet activated the online payment function. Please activate this function before making the payment。</div>
	`,
	myzhuce:'Personal registration',
	qiyezhuce:'Enterprise registration',
	tpfl:'Carbon emissions',
	dxfs:'Offsetting method',
	dxtip1:'Support environmental protection projects',
	dxtip2:'Use account carbon  balance',
	shengyu:'Residue',
	pjdj:'Project unit price',
	pjcheck:'Project selection',
	mycbcy:'Holding quantity',
	mycbcy2:'Insufficient holding quantity',
	dxok:'Offset successful',
	dximg:'Offset poster',
	dxbtn:'Offset',
	xzjiadian:'Please select home appliances',
	pfgj:'Total carbon emissions',
	mxrx:'The details are as follows',
	syb:'Back',
	xyb:'next ',
	tdxtips:'Offset quantity cannot be 0',
	
	xzhxtype:'Please select industry type',
	xzaddress:'Select Region',
	xtxqyncz:'Please enter the annual output value of the enterprise',
	wanyuan:'Ten thousand ￥',
	xzdate:'Please select a time',
	qtxwz:'Please provide complete information',
	jbxx:'Basic information',
	qtxschool:'Please enter the name of the school',
	qtxschooltype:'Please select the type of school',
	qtxjzmj:'Please enter the building area',
	qtxssrs:'Please enter the number of teachers and students',
	
	jtrk:'Household Size',
	zfmj:'Please select the housing area',
	gusuan:'estimate',
	jingsuan:'Actuary',
    unit: 'Number of people',
	jbts:'Please enter the number of days for the event',
	cbrs:'Please enter the number of participants',
	gzrs:'Please enter the number of viewers',
	kpj:'opening price',
	zjs:'Yesterday SIF',
	zgj:'highest',
	zdj:'lowest',
	gsgr:'Name',
	leix:'Type',
	diqu:'Address',
	tzc:'Carbon assets',
	riqis:'Date',
	zt:'State',
	xwzx:'News flash',
	wtlx:'Question type',
	qxzwtlx:'Please select the type of question',
	qmscxdwt:'Please describe the problem that has occurred',
	lxfs:'Please provide contact information',
	wt1:'dysfunction',
	wt2:'Product recommendations',
	wt3:'Other issues',
	lv:'Grade',
	chenhao:'Designation',
	ywc:'Completed',
	wwc:'incomplete',
	phb:'Carbon offsetting ranking',
	gxsj:'Update time',
	paim:'Ranking',
	toux:'Profile',
	yhu:'User name',
	shijian:'Time',
	qitas:'Other',
	y:'Clothing',
	s:'Food',
	z:'Stay',
	x:'Rows',
	y:'Using',
	lquzs:'Receive certificate',
	xzleix:'Select type',
	name:'Name',
	qtxname:'Please fill in your real name',
	zsphone:'Phone',
	dzzs:'Only electronic certificates',
	dzandzzzs:'Electron+Paper certificate',
	ssq:'City',
	address:'Address',
	xxaddress:'Please provide detailed address',
	zsleix:'Certificate Type',
	slgz:'Application rules',
	ltgm:'Zero carbon citizens',
	ltqy:'Zero carbon enterprises',
	slgz:'Application rules',
	dataisnonono:'Data loading failed, please refresh and try again',
	tjok:'Submitted successfully',
	tjoktips:'Successfully submitted, expected to be distributed within three working days',
	cbisno:'Your offset amount is insufficient, and you are currently unable to claim it',
	zhengshu:'Certificate',
	tgjl:'Carbon asset management records',
	tgl:'Custody volume',
	xtl:'Trust amount',
	wdzs:'My certificate',
	dxliang:'Offset quantity',
	hexl:'Verified quantity',
	getriqi:'Date of acquisition',
	xzzs:'Download certificate',
	lquzzzs:'Receive paper certificates',
	sqrq:'Application date',
	dsh:'Unaudited',
	shcg:'Review successful',
	shwtg:'Review failed',
	caozuo:'Operate',
	tiqu:'Extract',
	tiqunum:"Extraction quantity (tons)",
	txtzh:'Please carbon your carbon account',
	chakanjil:'View Records',
	zhuanzeng:'Carbon asset transfer',
	zhuanz:'Transfer',
	zhuanznum:'Transfer quantity',
	dfphone:'Please enter the recipient phone number',
	zhuanzok:'Transfer successful',
	zhuanzjil:'Carbon asset transfer records',
	zzlx:'Transfer type',
	zhuanc:'Transfer out',
	zhuanr:'to change into',
	wozzg:'I transferred it to',
	zzgw:'Transfer to me',
	chsq:'withdraw an application',
	qdchehui:'Determine withdraw an application',
	hxipt:'Please enter the name  or company name',
	hxipt2:'Please enter the purpose of verification',
	hdcg:'Verification successful',
	cyrq:'Holding date',
	wdqb:'My wallet',
	yue:'Balance',
	yuemx:'Balance details',
	jymx:'Transaction records',
	tixian:'Withdrawal',
	czhi:'Recharge',
	tixiantips1:'The service fee withdrawn is',
	tixiantips2:'After applying for withdrawal, we will contact you within three working days',
	qsypnzf:'Please use Paynow for payment',
	skzh:'Receiving account',
	czje:'Recharge amount',
	zftips:'Tip: After payment is completed, please upload a screenshot of the payment and we will review it for you',
	scimg:'Please upload a screenshot',
	scimgtips:'Uploading images can only be in jpg, jpeg, png, or gif format',
	zzsqz:'Applying in progress',
	txje:'Withdrawal amount',
	jine:'amount of money',
	laiyuan:'source',
	jydh:'Transaction number',
	jylx:'Transaction type',
	sxf:'Commission',
	genghuan:'Replace',
	xiugais:'modify',
	save:'save',
	updphone:'Change phone number',
}