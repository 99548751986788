export default {
    lg:'繁',
    loginno:'登錄失效，請先重新登錄',
    cbdx:'碳抵消量',
	cg:'超過',
	yh:'用戶',
	pgb:'排行榜',
	ryxz:'榮譽勛章',
	jlmx:'記錄明細',
	lvsm:'等級說明',
	cbcy:'碳持有量',
	cbcy2:'碳資產持有量',
	cbdixiaol:'碳資產抵消',
	cbdixiaol2:'碳資產抵消記錄',
	copylink:'復製鏈接',
	fx:'分享',
	xz:'勛章',
	copyok:'復製成功',
	sys:'算一算',
	jsjg:'計算結果',
	tpfw:'您的碳排放為',
	quxiao:'取消',
	cbhx:'核消',
	qtrsj:'請填入數據',
	more:'更多',
	liuyue:'近六月',
	yinian:'近一年',
	wunian:'近五年',
	jiage:'價格',
	click:'點擊這裏',
	sqtgqt:'申請托管其他碳資產',
	sqxtqt:'申請信托其他碳資產',
	zzsqtg:'正在申請托管中',
	zzsqxg:'正在申請信托中',
	zanwu:'暫無數據',
	pjno:'項目編號',
	pjtype:'項目類別',
	pjmc:'項目名稱',
	pjdate:'項目年份',
	pjid:'項目ID',
	num:'數量',
	cbnums:'碳信用數量',
	pjkssj:'計入期開始時間',
	pjjssj:'計入期結束時間',
	datepx:'時間排序',
	shengxu:'升序',
	jiangxu:'降序',
	sqtg:'申請碳托管',
	copylink:'正在申請中',
	sqxg:'申請碳信托',
	sqtips:'申請後,我們將在2個工作日內與您取得聯系,確定要申請嗎?',
	tips:'提示',
	sq:'申請',
	reset:'重置',
	ok:'確定',
	tj:'提交',
	ton:'噸',
	shaixuan:'篩選',
	qtxqy:'請填寫企業名稱',
	qtxpjname:'請填寫項目負責人姓名',
	qtxem:'請填寫郵箱',
	ems:'郵箱',
	qtxpjid:'請填寫項目ID',
	rzjg:'碳資產認證機構',
	qtxxmbh:'請填寫項目編號',
	qtxpj:'請填寫項目名稱',
	qtxpjtype:'請填寫項目類別',
	qxzrygm:'請選擇人員規模',
	qxz:'請選擇',
	txynf:'碳信用年份',
	xmdate:'選擇日期',
	qtxxlh:'請填寫碳信用序列號',
	qtxnum:'請填寫數量(噸)',
	pjdata:'項目計入日期',
	kssj:'開始日期',
	jsrq:'結束日期',
	embzq:'郵箱格式不正確',
	fscode:'短信發送成功',
	zsjl:'在售記錄',
	xjjl:'下架記錄',
	xiajias:'下架',
	qrxiajia:'確認是否下架?',
	xiajiaok:'下架成功',
	gmjl:'購買記錄',
	buypj:'購買',
	buypjname:'購買項目',
	renbuy:'人已購買此項目',
	gjdq:'國家地區',
	cyz:'持有者',
	buydate:'購買年份',
	buynum:'購買數量',
	fkprise:'付款金額',
	selljg:'出售價格',
	sell:'出售',
	sellqj:'出售價格區間',
	
	zuidj:'最低價',
	zuigj:'最高價',
	sellnum:'出售數量',
	sellok:'出售成功',
	
	zyjytips:'自由交易碳資產列表',
	czgl:'操作管理',
	sellcb:'出售碳資產',
	jyjl:'交易記錄',
	xiangq:'詳情',
	upapp1:'想擁有更完整的互動體驗嗎?',
	upapp2:'訪問我們的移動端頁面,立即享受低碳生活',
	upapp3:'掃碼訪問氣候商店小程序',
	upapp4:'準備好低碳之旅了嗎?',
	upapp5:'讓我們一起為地球減負!',
	upapp6:'計算個人碳排放',
	upapp7:'每天學習一個碳知識',
	upapp8:'探索綠色主題，踐行綠色理念',
	uppwd:'修改密碼',
	wjpwd:'忘記密碼',
	area:'區號',
	zhuce:'註冊',
	qtxphone:'請填寫手機號',
	qtxcode:'請填寫驗證碼',
	getcode:'獲取驗證碼',
	xzarea:'請選擇區號',
	qtxnames:'請填寫姓名',
	ydxy:'我已經閱讀並同意',
	ydxy2:'請先閱讀協議',
	yszc:'隱私政策',
	and:'和',
	fwxy:'服務協議',
	pwd:'密碼',
	login:'登錄',
	outlogin:'退出登錄',
	qtxpwd:'請填寫密碼',
	qtxpwd2:'請再次輸入密碼',
	qtxpwd3:'兩次輸入密碼不一致',
	djjr:'點擊進入',
	loading:'功能正在開發中，敬請期待',
	backhome:'返回首頁',
	backs:'返回',
	mycb:'我的賬戶',
	myinfo:'我的信息',
	lxwm:'聯系我們',
	zcok:'註冊成功',
	buypr:'支付金額',
	mybuy:'余額支付',
	buynotips:'余額不足 請使用其他方式支付',
	myye:'當前余額為',
	zfbbuy:'支付寶支付',
	buybtn:'支付',
	buyno:'訂單異常，請重試',
	buytips:'提示:付款成功後,不設退款,請謹慎操作。',
	buyok:'支付成功',
	buyoktips:'碳資產項目預計將在十分鐘內到賬,屆時會有短信通知您,請您耐心等待',
	buyfail:'支付失敗',
	sbtips:`
		<div>請檢查以下問題</div>
		<div>1.您取消了支付</div>
		<div>2.您的余額不足，請確保余額充足</div>
		<div>3.您的付款金額超過了設置的在線付款限額</div>
		<div>4.您尚未開通網上支付功能，付款前請您先開通該功能。</div>
	`,
	myzhuce:'個人註冊',
	qiyezhuce:'企業註冊',
	
	
	
	
	tpfl:'碳排放量',
	dxfs:'抵消方式',
	dxtip1:'支持環保項目',
	dxtip2:'使用賬戶碳信用余額',
	shengyu:'剩余',
	pjdj:'項目單價',
	pjcheck:'項目選擇',
	mycbcy:'持有數量',
	mycbcy2:'持有數量不足',
	dxok:'抵消成功',
	dximg:'抵消海報',
	dxbtn:'抵消',
	xzjiadian:'請選擇家電',
	pfgj:'碳排放量共計',
	mxrx:'明細如下',
	syb:'上一步',
	xyb:'下一步',
	tdxtips:'抵消數量不能為0',
	
	xzhxtype:'請選擇行業類型',
	xzaddress:'選擇地區',
	xtxqyncz:'請填寫企業年產值',
	wanyuan:'萬元',
	xzdate:'請選擇時間',
	qtxwz:'請填寫完整的信息',
	jbxx:'基本信息',
	qtxschool:'請填寫學校名稱',
	qtxschooltype:'請選擇學校類型',
	qtxjzmj:'請填寫建築面積',
	qtxssrs:'請填寫師生人數',
	
	jtrk:'家庭人口',
	zfmj:'請選擇住房面積',
	gusuan:'估算',
	jingsuan:'精算',
    unit: '人數',
	jbts:'請填寫舉辦天數',
	cbrs:'請填寫參與人數',
	gzrs:'請填寫觀眾人數',
	kpj:'開盤價',
	zjs:'昨結算',
	zgj:'最高價',
	zdj:'最低價',
	gsgr:'公司/個人名稱',
	leix:'類型',
	diqu:'地區',
	tzc:'碳資產',
	riqis:'日期',
	zt:'狀態',
	xwzx:'新聞資訊',
	wtlx:'問題類型',
	qxzwtlx:'請選擇問題類型',
	qmscxdwt:'請描述出現的問題',
	lxfs:'請填寫聯系方式',
	wt1:'功能異常',
	wt2:'產品建議',
	wt3:'其他問題',
	lv:'等級',
	chenhao:'稱號',
	ywc:'已完成',
	wwc:'未完成',
	phb:'碳抵消排行榜',
	gxsj:'更新時間',
	paim:'排名',
	toux:'頭像',
	yhu:'用戶名',
	shijian:'時間',
	qitas:'其他',
	y:'衣',
	s:'食',
	z:'住',
	x:'行',
	y:'用',
	lquzs:'申領證書',
	xzleix:'選擇類型',
	name:'姓名',
	qtxname:'請填寫真實姓名',
	zsphone:'手機號',
	dzzs:'僅電子證書',
	dzandzzzs:'電子+紙質證書',
	ssq:'省市區',
	address:'地址',
	xxaddress:'請填寫詳細地址',
	zsleix:'證書類型',
	slgz:'申領規則',
	ltgm:'零碳公民',
	ltqy:'零碳企業',
	slgz:'申領規則',
	dataisnonono:'數據加載失敗,請刷新後重試',
	tjok:'提交成功',
	tjoktips:'提交成功，預計三個工作日內發放',
	cbisno:'您的抵消量不足,暫不能領取當前',
	zhengshu:'證書',
	tgjl:'碳資產管理記錄',
	tgl:'托管量',
	xtl:'信托量',
	wdzs:'我的證書',
	dxliang:'抵消數量',
	hexl:'核證數量',
	getriqi:'獲得日期',
	xzzs:'下載證書',
	lquzzzs:'領取紙質證書',
	sqrq:'申請日期',
	dsh:'待審核',
	shcg:'審核成功',
	shwtg:'審核未通過',
	caozuo:'操作',
	tiqu:'提取',
	tiqunum:"提取數量(噸)",
	txtzh:'請填寫您的碳賬戶',
	chakanjil:'查看記錄',
	zhuanzeng:'碳資產轉贈',
	zhuanz:'轉贈',
	zhuanznum:'轉贈數量',
	dfphone:'請輸入對方手機號',
	zhuanzok:'轉贈成功',
	zhuanzjil:'碳資產轉贈記錄',
	zzlx:'轉贈類型',
	zhuanc:'轉出',
	zhuanr:'轉入',
	wozzg:'我轉贈給',
	zzgw:'轉贈給我',
	chsq:'撤回申請',
	qdchehui:'確定要撤回申請',
	hxipt:'請輸入核銷人姓名或企業名稱',
	hxipt2:'請輸入核銷用途',
	hdcg:'核銷成功',
	cyrq:'持有日期',
	wdqb:'我的錢包',
	yue:'余額',
	yuemx:'余額明細',
	jymx:'交易記錄',
	tixian:'提現',
	czhi:'充值',
	tixiantips1:'提現的服務費為',
	tixiantips2:'申請提現後,我們將在三個工作日內聯系您',
	qsypnzf:'請使用Paynow支付',
	skzh:'收款賬戶',
	czje:'充值金額',
	zftips:'提示:支付完成後，請上傳付款截圖，我們將為您審核',
	scimg:'請上傳截圖',
	scimgtips:'上傳圖片只能是 jpg、jpeg、png或gif 格式 ',
	zzsqz:'正在申請中',
	txje:'提現金額',
	jine:'金額',
	laiyuan:'來源',
	jydh:'交易單號',
	jylx:'交易類型',
	sxf:'手續費',
	genghuan:'更換',
	xiugais:'修改',
	save:'保存',
	updphone:'更換手機號',
}