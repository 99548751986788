export default {
	lg:'简',
	loginno:'登录失效，请先重新登录',
    cbdx:'碳抵消量',
	cg:'超过',
	yh:'用户',
	pgb:'排行榜',
	ryxz:'荣誉勋章',
	jlmx:'记录明细',
	lvsm:'等级说明',
	cbcy:'碳持有量',
	cbcy2:'碳资产持有量',
	cbdixiaol:'碳资产抵消',
	cbdixiaol2:'碳资产抵消记录',
	copylink:'复制链接',
	fx:'分享',
	xz:'勋章',
	copyok:'复制成功',
	sys:'算一算',
	jsjg:'计算结果',
	tpfw:'您的碳排放为',
	quxiao:'取消',
	cbhx:'核消',
	qtrsj:'请填入数据',
	more:'更多',
	liuyue:'近六月',
	yinian:'近一年',
	wunian:'近五年',
	jiage:'价格',
	click:'点击这里',
	sqtgqt:'申请托管其他碳资产',
	sqxtqt:'申请信托其他碳资产',
	zzsqtg:'正在申请托管中',
	zzsqxg:'正在申请信托中',
	zanwu:'暂无数据',
	pjno:'项目编号',
	pjtype:'项目类别',
	pjmc:'项目名称',
	pjdate:'项目年份',
	pjid:'项目ID',
	num:'数量',
	cbnums:'碳信用数量',
	pjkssj:'计入期开始时间',
	pjjssj:'计入期结束时间',
	datepx:'时间排序',
	shengxu:'升序',
	jiangxu:'降序',
	sqtg:'申请碳托管',
	copylink:'正在申请中',
	sqxg:'申请碳信托',
	sqtips:'申请后,我们将在2个工作日内与您取得联系,确定要申请吗?',
	tips:'提示',
	sq:'申请',
	reset:'重置',
	ok:'确定',
	tj:'提交',
	ton:'吨',
	shaixuan:'筛选',
	qtxqy:'请填写企业名称',
	qtxpjname:'请填写项目负责人姓名',
	qtxem:'请填写邮箱',
	ems:'邮箱',
	qtxpjid:'请填写项目ID',
	rzjg:'碳资产认证机构',
	qtxxmbh:'请填写项目编号',
	qtxpj:'请填写项目名称',
	qtxpjtype:'请填写项目类别',
	qxzrygm:'请选择人员规模',
	qxz:'请选择',
	txynf:'碳信用年份',
	xmdate:'选择日期',
	qtxxlh:'请填写碳信用序列号',
	qtxnum:'请填写数量(吨)',
	pjdata:'项目计入日期',
	kssj:'开始日期',
	jsrq:'结束日期',
	embzq:'邮箱格式不正确',
	fscode:'短信发送成功',
	zsjl:'在售记录',
	xjjl:'下架记录',
	xiajias:'下架',
	qrxiajia:'确认是否下架?',
	xiajiaok:'下架成功',
	gmjl:'购买记录',
	buypj:'购买',
	buypjname:'购买项目',
	renbuy:'人已购买此项目',
	gjdq:'国家地区',
	cyz:'持有者',
	buydate:'购买年份',
	buynum:'购买数量',
	fkprise:'付款金额',
	selljg:'出售价格',
	sell:'出售',
	sellqj:'出售价格区间',
	
	zuidj:'最低价',
	zuigj:'最高价',
	sellnum:'出售数量',
	sellok:'出售成功',
	
	zyjytips:'自由交易碳资产列表',
	czgl:'操作管理',
	sellcb:'出售碳资产',
	jyjl:'交易记录',
	xiangq:'详情',
	upapp1:'想拥有更完整的互动体验吗?',
	upapp2:'访问我们的移动端页面,立即享受低碳生活',
	upapp3:'扫码访问气候商店小程序',
	upapp4:'准备好低碳之旅了吗?',
	upapp5:'让我们一起为地球减负!',
	upapp6:'计算个人碳排放',
	upapp7:'每天学习一个碳知识',
	upapp8:'探索绿色主题，践行绿色理念',
	uppwd:'修改密码',
	wjpwd:'忘记密码',
	area:'区号',
	zhuce:'注册',
	qtxphone:'请填写手机号',
	qtxcode:'请填写验证码',
	getcode:'获取验证码',
	xzarea:'请选择区号',
	qtxnames:'请填写姓名',
	ydxy:'我已经阅读并同意',
	ydxy2:'请先阅读协议',
	yszc:'隐私政策',
	and:'和',
	fwxy:'服务协议',
	pwd:'密码',
	login:'登录',
	outlogin:'退出登录',
	qtxpwd:'请填写密码',
	qtxpwd2:'请再次输入密码',
	qtxpwd3:'两次输入密码不一致',
	djjr:'点击进入',
	loading:'功能正在开发中，敬请期待',
	backhome:'返回首页',
	backs:'返回',
	mycb:'我的账户',
	myinfo:'我的信息',
	lxwm:'联系我们',
	zcok:'注册成功',
	buypr:'支付金额',
	mybuy:'余额支付',
	buynotips:'余额不足 请使用其他方式支付',
	myye:'当前余额为',
	zfbbuy:'支付宝支付',
	buybtn:'支付',
	buyno:'订单异常，请重试',
	buytips:'提示:付款成功后,不设退款,请谨慎操作。',
	buyok:'支付成功',
	buyoktips:'碳资产项目预计将在十分钟内到账,届时会有短信通知您,请您耐心等待',
	buyfail:'支付失败',
	sbtips:`
		<div>请检查以下问题</div>
		<div>1.您取消了支付</div>
		<div>2.您的余额不足，请确保余额充足</div>
		<div>3.您的付款金额超过了设置的在线付款限额</div>
		<div>4.您尚未开通网上支付功能，付款前请您先开通该功能。</div>
	`,
	myzhuce:'个人注册',
	qiyezhuce:'企业注册',
	
	
	
	
	tpfl:'碳排放量',
	dxfs:'抵消方式',
	dxtip1:'支持环保项目',
	dxtip2:'使用账户碳信用余额',
	shengyu:'剩余',
	pjdj:'项目单价',
	pjcheck:'项目选择',
	mycbcy:'持有数量',
	mycbcy2:'持有数量不足',
	dxok:'抵消成功',
	dximg:'抵消海报',
	dxbtn:'抵消',
	xzjiadian:'请选择家电',
	pfgj:'碳排放量共计',
	mxrx:'明细如下',
	syb:'上一步',
	xyb:'下一步',
	tdxtips:'抵消数量不能为0',
	
	xzhxtype:'请选择行业类型',
	xzaddress:'选择地区',
	xtxqyncz:'请填写企业年产值',
	wanyuan:'万元',
	xzdate:'请选择时间',
	qtxwz:'请填写完整的信息',
	jbxx:'基本信息',
	qtxschool:'请填写学校名称',
	qtxschooltype:'请选择学校类型',
	qtxjzmj:'请填写建筑面积',
	qtxssrs:'请填写师生人数',
	
	jtrk:'家庭人口',
	zfmj:'请选择住房面积',
	gusuan:'估算',
	jingsuan:'精算',
    unit: '人数',
	jbts:'请填写举办天数',
	cbrs:'请填写参与人数',
	gzrs:'请填写观众人数',
	kpj:'开盘价',
	zjs:'昨结算',
	zgj:'最高价',
	zdj:'最低价',
	gsgr:'公司/个人名称',
	leix:'类型',
	diqu:'地区',
	tzc:'碳资产',
	riqis:'日期',
	zt:'状态',
	xwzx:'新闻资讯',
	wtlx:'问题类型',
	qxzwtlx:'请选择问题类型',
	qmscxdwt:'请描述出现的问题',
	lxfs:'请填写联系方式',
	wt1:'功能异常',
	wt2:'产品建议',
	wt3:'其他问题',
	lv:'等级',
	chenhao:'称号',
	ywc:'已完成',
	wwc:'未完成',
	phb:'碳抵消排行榜',
	gxsj:'更新时间',
	paim:'排名',
	toux:'头像',
	yhu:'用户名',
	shijian:'时间',
	qitas:'其他',
	y:'衣',
	s:'食',
	z:'住',
	x:'行',
	y:'用',
	lquzs:'申领证书',
	xzleix:'选择类型',
	name:'姓名',
	qtxname:'请填写真实姓名',
	zsphone:'手机号',
	dzzs:'仅电子证书',
	dzandzzzs:'电子+纸质证书',
	ssq:'省市区',
	address:'地址',
	xxaddress:'请填写详细地址',
	zsleix:'证书类型',
	slgz:'申领规则',
	ltgm:'零碳公民',
	ltqy:'零碳企业',
	slgz:'申领规则',
	dataisnonono:'数据加载失败,请刷新后重试',
	tjok:'提交成功',
	tjoktips:'提交成功，预计三个工作日内发放',
	cbisno:'您的抵消量不足,暂不能领取当前',
	zhengshu:'证书',
	tgjl:'碳资产管理记录',
	tgl:'托管量',
	xtl:'信托量',
	wdzs:'我的证书',
	dxliang:'抵消数量',
	hexl:'核证数量',
	getriqi:'获得日期',
	xzzs:'下载证书',
	lquzzzs:'领取纸质证书',
	sqrq:'申请日期',
	dsh:'待审核',
	shcg:'审核成功',
	shwtg:'审核未通过',
	caozuo:'操作',
	tiqu:'提取',
	tiqunum:"提取数量(吨)",
	txtzh:'请填写您的碳账户',
	chakanjil:'查看记录',
	zhuanzeng:'碳资产转赠',
	zhuanz:'转赠',
	zhuanznum:'转赠数量',
	dfphone:'请输入对方手机号',
	zhuanzok:'转赠成功',
	zhuanzjil:'碳资产转赠记录',
	zzlx:'转赠类型',
	zhuanc:'转出',
	zhuanr:'转入',
	wozzg:'我转赠给',
	zzgw:'转赠给我',
	chsq:'撤回申请',
	qdchehui:'确定要撤回申请',
	hxipt:'请输入核销人姓名或企业名称',
	hxipt2:'请输入核销用途',
	hdcg:'核销成功',
	cyrq:'持有日期',
	wdqb:'我的钱包',
	yue:'余额',
	yuemx:'余额明细',
	jymx:'交易记录',
	tixian:'提现',
	czhi:'充值',
	tixiantips1:'提现的服务费为',
	tixiantips2:'申请提现后,我们将在三个工作日内联系您',
	qsypnzf:'请使用Paynow支付',
	skzh:'收款账户',
	czje:'充值金额',
	zftips:'提示:支付完成后，请上传付款截图，我们将为您审核',
	scimg:'请上传截图',
	scimgtips:'上传图片只能是 jpg、jpeg、png或gif 格式 ',
	zzsqz:'正在申请中',
	txje:'提现金额',
	jine:'金额',
	laiyuan:'来源',
	jydh:'交易单号',
	jylx:'交易类型',
	sxf:'手续费',
	genghuan:'更换',
	xiugais:'修改',
	save:'保存',
	updphone:'更换手机号',
}