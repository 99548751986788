<template>
  <div>
    <div class="head"  v-if="headloading">
      <div class="box">
        <div class="head_left">
          <img src="@/assets/logos.png" @click="$carbon.tolink('/',2)" alt="" />
        </div>
        <div class="head_right">
          <div class="head_right_box1">
            <li
              v-show="item.topShow == 1"
              v-for="(item, index) in headlist"
              :key="index"
              @click="tolinks(item.link, item.type)"
              
              >
              
              <el-dropdown placement='top'  v-if="item.item" :show-timeout="0">
                <span class="el-dropdown-link menu"  :class="[item.link == $route.meta.type ? 'headtitlecheck' : '']">
                  {{ item.name }} <i class="el-icon-arrow-down"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(chilitem,idx2) in item.item"
                    v-show="chilitem.topShow == 1"
                    :key="idx2"
                    @click.native="tolink(chilitem.link,chilitem.type)"
                    >{{ chilitem.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <span class="el-dropdown-link menu" v-else  :class="[item.link == $route.meta.type ? 'headtitlecheck' : '']">
                  {{ item.name }}
              </span>
            </li>
            <span>
              <el-dropdown :show-timeout="0">
                <span class="el-dropdown-link lgspan">
                  <img class="headimgicon" src="@/assets/icon/yuyan.png" alt="" />
                  {{ $lg.lg }} <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="switchlg('zh-cn')">简</el-dropdown-item>
                  <el-dropdown-item @click.native="switchlg('zh-hk')">繁</el-dropdown-item>
                  <el-dropdown-item @click.native="switchlg('en-us')">EN</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="border-bott"></div>
  </div>
</template>
<script>
import { homeFooter } from "@/api/index";
import {PageInfonav} from "@/api/newapi"

export default {
  data() {
    return {
      headloading:false,
      headlist: [],
      headindx: 999,
    };
  },
  methods: {
    tourl(url) {
      window.location.href = url
    },
    tolinks(link, type) {
      if (type) {
        if (this.$route.path == link) return;
        this.$router.push(link);
      }
    },
    tolink(link,type){
      if (type == 1){
        this.$carbon.tolink(link,2)
      }else{
        window.open(link)
        // window.location.href = link
      }
    },
    switchlg(lg) {
      console.log(lg);
      var languagetype = localStorage.getItem("language");
      if (languagetype === lg) {
        return;
      } else {
        localStorage.setItem("language", lg);
        location.reload();
      }
    },
  },
  mounted() {
    //  homeFooter().then((res) => {
    //   this.headloading = true
    //   this.headlist = res.data.nav;
    // });

    PageInfonav({type:this.$carbon.version == 1?1:2}).then(res =>{
      this.headlist = res.data;
      this.headloading = true
    })
  }
};
</script>
<style lang="scss" scoped>
.head {
  width: 100%;
  background-color: white;
  // border-bottom: 1px solid #bbbbbb;
}
.border-bott {
  width: 100%;
  min-width: 1480px;
  border-bottom: 1px solid #ebebeb;
}
.box {
  // border-bottom: 1px solid #bbbbbb;
  min-width: calc(1480px - 40px);
  width: calc(80% - 40px);
  padding:10px;
  margin: 0 auto !important;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .head_left {
    img {
      width: 120px;
      cursor: pointer;
    }
  }
  .head_right {
    width: 1100px;
    color: #585858;
    display: flex;
    align-items: center;

    .head_right_box1 {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      li {
        // font-weight: bold;
        list-style-type: none;
        // transition-duration:0.1s;
        display: inline-block;        
        // border-bottom: 0px solid #585858;
        border-bottom: 2px solid white;
        text-align: center;
        cursor: pointer;
        .el-dropdown-link {
          font-size: 17px;
        }
      }
    }
    .head_right_box2 {
      width: 200px;
    }
  }
}
.menu{
  padding-bottom: 3px;
}
.menu:hover {
  border-bottom: 2px solid #00a0e9 !important;
  color: #00a0e9;
}

.headtitlecheck {
  border-bottom: 2px solid #00a0e9 !important;
    color: #00a0e9;
}
.lgspan {
  display: block;
  background-color: #f0f0f0;
  border-radius: 50px;
  padding: 5px 10px;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.headimgicon {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}


</style>
