<template>
  <div class="home" v-loading="indexloading">
    <!-- 轮播 -->
    <div class="bennerbox" v-if="headimglist.length > 0">
      <el-carousel trigger="click" :height="bennerheight + 'px'" :interval="4000">
        <el-carousel-item v-for="(item, index) in headimglist" :key="index">
          <img class="headimg" ref="heiimg" :src="$carbon.baseurl + item.img" alt="" @load="imgload"
            @click="newslink(item.state, item.url)" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 轮播结束 -->
    <div class="wid1280">
      <!-- 今日碳价 -->
      <div class="mgtp2 carbonprice" v-if="day_price.name" @click="$carbon.tolink('/carbonecharts', 2)">
        <div>
          <span class="caprname"> <img class="laba" src="@/assets/lbbbb.gif" alt=""> {{ day_price.name }}</span>
          <span class="capr_pr" :class="day_price.diffPrice > 0 ? 'colo' : 'colo2'">{{ day_price.closing }}</span>
          <span class="capr_qj" :class="day_price.diffPrice > 0 ? 'colo' : 'colo2'">
            {{ day_price.diffPrice }} ({{ day_price.diffRate }}%)
          </span>
        </div>
        <div style="margin: 0 30px;">|</div>
        <div class="car_jg">
          <span>{{ day_price.day }}</span>
          <span>{{ $lg.kpj }}:{{ day_price.opening }}</span>
          <span>{{ $lg.zjs }}:{{ day_price.yesterdayPrice }}</span>
          <span>{{ $lg.zgj }}:{{ day_price.high }}</span>
          <span>{{ $lg.zdj }}:{{ day_price.low }}</span>
        </div>
      </div>
      <!-- 今日碳价结束 -->

      <!-- 表格开始 -->
      <div class="mgtp2 eltables" v-if="enterprisesList.length > 0">
        <el-table header-cell-style="background-color: rgb(232 232 232);color:#5d5d5d" :data="enterprisesList[prisesListindex]" height="193px" stripe :border="true" style="width: 100%">
          <el-table-column prop="name" :label="$lg.gsgr" width="180" />
          <el-table-column prop="type" :label="$lg.leix" />
          <el-table-column prop="region" :label="$lg.diqu" />
          <el-table-column prop="assets" :label="$lg.tzc" />
          <el-table-column prop="day" :label="$lg.riqis" />
          <el-table-column prop="state" :label="$lg.zt" />
        </el-table>
      </div>


      <!-- 表格结束 -->

    </div>


    <!-- 碳资产开发 allbox -->
    <div class="mgtp " v-if="carbon_asset_development.title">
      <div class="wid1280">
        <div class="boxtitlecontent">
          <div class="tit">{{ carbon_asset_development.title }}</div>
          <div class="cont">
            {{ carbon_asset_development.content }}
          </div>
        </div>
        <div class="carbon_tjy">
          <div v-for="(item, index) in carbon_asset_development.itemList" :key="index"
            @click="tolink2(item.link, item.outLink)" class=" backcolor">
            <img class="imgtransition" :src="$carbon.baseurl + item.img" alt="" />
            <div class="carbon_tjy_content flextwos">
              <div class="fl1s_1">
                <div class="carbon_tjy_tltle">{{ item.name }}</div>
                <div class="carbon_tjy_cont ellipsis">{{ item.content }}</div>
              </div>
              <div class="fl1s_2">
                <img src="../assets/xz.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 碳资产开发结束 -->
    <!-- 碳交易 -->
    <div class="mgtp " v-if="carbon_trading.title">
      <div class="wid1280">
        <div class="boxtitlecontent">
          <div class="tit">{{ carbon_trading.title }}</div>
          <div class="cont">
            {{ carbon_trading.content }}
          </div>
        </div>
        <div class="carbon_tjy">
          <div v-for="(item, index) in carbon_trading.itemList" :key="index" @click="tolink2(item.link, item.outLink)"
            class=" backcolor">
            <img class="imgtransition" :src="$carbon.baseurl + item.img" alt="" />
            <div class="carbon_tjy_content flextwos">
              <div class="fl1s_1">
                <div class="carbon_tjy_tltle">{{ item.name }}</div>
                <div class="carbon_tjy_cont ellipsis">{{ item.content }}</div>
              </div>
              <div class="fl1s_2">
                <img src="../assets/xz.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 碳交易结束 -->
    <div class="wid1280">

      <!-- 碳计算 -->
      <div class="mgtp imgbox hdimgs_details bors ">
        <img @click="$carbon.tolink(introduce.link, 2)" class="bors imgtransition cupo"
          :src="$carbon.baseurl + introduce.img" alt="" />
      </div>
      <!-- 碳计算结束 -->

      <!-- 新闻开始 -->
      <div class="news_box mgtp">
        <div class="boxnews_title">
          <div class=" titflex">
            <div class="tit">{{ $lg.xwzx }}</div>
            <div class="newsmennu">
              <div v-for="(item, index) in news_title" :class="{ 'newsmennu_check': newsindex == index }" :key="index"
                @click="checknews(index)">{{
                  item.name }}</div>
            </div>
          </div>
          <div class="news_contentbox" v-if="newslist.length > 0" v-loading="newsloading">
            <div>
              <div class="news_left_box" @click="$carbon.tolink(`/news?id=${newslist[0].id}`, 2)">
                <div class="imgbox imgtransition"><img :src="$carbon.baseurl + newslist[0].img" alt=""></div>
                <div class="news_left_text">{{ newslist[0].introduce }}</div>
              </div>
            </div>
            <div class="news_right_box">
              <div @click="$carbon.tolink(`/news?id=${item.id}`, 2)" v-for="(item, index) in newslist" v-show="index > 0"
                :key="index" class="news_right_item">
                <div>{{ item.introduce }}</div>
                <div>{{ formatDateTime(item.createTime) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 新闻开始结束 -->

      <!-- 零碳家园开始 -->
      <!-- <div class="mgtp" style="padding-top:50px">
        <div class="boxtitlecontent">
          <div class="tit">{{ zero_carbon_home.name }}</div>
          <div class="cont">{{ zero_carbon_home.content }}</div>
        </div>
        <div class="carbon_tjy">
          <div @click="tolink2(item.link, item.out_link)" v-for="(item, index) in zero_carbon_home.item" :key="index"
            class=" backcolor">
            <img class="imgtransition" :src="$carbon.baseurl + item.img" alt="" />
            <div class="carbon_tjy_content flextwos">
              <div class="fl1s_1">
                <div class="carbon_tjy_tltle ellipsis">{{ item.name }}</div>
              </div>
              <div class="fl1s_2">
                <img src="../assets/xz.png" alt="" />
              </div>
            </div>
          </div>

        </div>
      </div> -->
      <!-- 零碳家园结束 -->
      <!-- 绿色碳汇开始 -->
      <!-- <div class="mgtp" style="padding-top:50px">
        <div class="boxtitlecontent">
          <div class="tit">{{ green_carbon_sink.name }}</div>
          <div class="cont">{{ green_carbon_sink.content }}</div>
        </div>
        <div class="carbon_tjy">
          <div v-for="(item, index) in green_carbon_sink.item" :key="index" @click="tolink2(item.link, item.out_link)"
            class=" backcolor">
            <img class="imgtransition" :src="$carbon.baseurl + item.img" alt="" />
            <div class="carbon_tjy_content flextwos">
              <div class="fl1s_1">
                <div class="carbon_tjy_tltle">{{ item.name }}</div>
                <div class="carbon_tjy_cont ellipsis">{{ item.title }}</div>
              </div>
              <div class="fl1s_2">
                <img src="../assets/xz.png" alt="" />
              </div>
            </div>
          </div>

        </div>
      </div> -->
      <!-- 绿色碳汇结束 -->



    </div>
    <!-- <div class="ewmxz">
      <img src="@/assets/xzewm.png" alt="">
      <div class="sz">{{ $lg.tkj.xzapp }}</div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { homePage } from "@/api/index";
import { newhomePage, articlearticleType, articlelist } from "@/api/newapi"
export default {
  components: {},
  data() {
    return {
      bennerheight: "340",
      btnshow: false,
      headimglist: [],
      enterprisesList: [],
      prisesListindex: 0,
      introduce: {
        img: "",
        link: "",
      },
      carbon_information: {
        img: "",
        link: "",
      },
      day_price: {
        closing: "",
        day: "",
        diffPrice: '',
        diffRate: '',
        high: "",
        low: "",
        name: "",
        nowPrice: '',
        opening: "",
        timestamp: '',
        yesterdayPrice: ""
      },
      carbon_calculation: {
        img: "",
        link: "",
      },

      carbon_trading: {
        name: "",
        title: "",
        content: "",
        img: "",
        link: "",
        state: 1,
        itemList: [],
      },
      carbon_asset_development: {
        name: "",
        title: "",
        content: "",
        img: "",
        link: "",
        state: 1,
        itemList: [],
      },
      carbon_accounting: {
        name: "",
        title: "",
        content: "",
        img: "",
        link: "",
        state: 1,
        item: [],
      },
      zero_carbon_home: {
        name: "",
        title: "",
        content: "",
        img: "",
        link: "",
        state: 1,
        item: [],
      },
      green_carbon_sink: {
        name: "",
        title: "",
        content: "",
        img: "",
        link: "",
        state: 1,
        out_link: 1,
        link_other: "",
        bg_color: "",
        item: [],

      },
      news_title: [],
      newslist: [],
      newsindex: 0,
      indexloading: true,
      intervalId: ''
    };
  },
  methods: {
    imgload() {
      this.$nextTick(() => {
        if (this.$refs.heiimg[0]) {
          this.bennerheight = this.$refs.heiimg[0].height;
        }

      });
    },
    tolink2(link, type) {
      if (type == 1) {
        this.$carbon.tolink(link, 2);
      } else {
        window.open(link+`?lang=${localStorage.getItem('language')}`)
        // window.location.href = link;
      }
    },
    newslink(type, link) {
      if (type == 1) {
        this.$carbon.tolink(link, 2);
      } else {
        window.open(link)
        // window.location.href = link;
      }
    },
    //分割数组 
    group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    },
    getnewslist(type) {
      this.newsloading = true
      articlelist({ type, pageNum: 1, pageSize: 7 }).then((res) => {
        this.newsloading = false
        console.log(res)
        this.newslist = res.data.records
      })
    },
    checknews(index) {
      this.newsindex = index
      this.getnewslist(this.news_title[index].id)
    },
    formatDateTime(date) {
      let times = new Date(date)
      const year = times.getFullYear();
      const month = String(times.getMonth() + 1).padStart(2, '0');
      const day = String(times.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  },
  created() {
    if (localStorage.getItem("token")) {
      this.btnshow = true
    } else {
      this.btnshow = false
    }
    // console.log(navigator.userAgent)
  },
  mounted() {
    const that = this
    // homePage().then((res) => {
    //   this.indexloading = false;
    //   // this.carbon_trading = res.data.content.carbon_trading;
    //   this.carbon_accounting = res.data.content.carbon_accounting;
    //   this.zero_carbon_home = res.data.content.zero_carbon_home;
    //   this.carbon_information = res.data.content.carbon_information;
    //   if (res.data.content.carbon_calculation) this.carbon_calculation = res.data.content.carbon_calculation;
    //   // this.headgroup = this.group(res.data.userList, 3);
    //   // this.article = res.data.article
    //   this.green_carbon_sink = res.data.content.green_carbon_sink
    //   // console.log(this.headgroup);
    // });
    this.indexloading = true;
    newhomePage().then((res) => {
      
      this.indexloading = false
      this.headimglist = res.data.banner;
      this.day_price = res.data.dayPrice
      this.introduce = res.data.bodyItem.carbon_calculation_new;
      this.carbon_trading = res.data.bodyItem.carbon_trading;
      this.carbon_asset_development = res.data.bodyItem.carbon_asset_development;
      if (res.data.enterprisesList) {
        this.enterprisesList = this.group(res.data.enterprisesList, 3)
        this.intervalId = setInterval(function () {
          if (that.prisesListindex == that.enterprisesList.length - 1) {
            that.prisesListindex = 0
          }else{
            that.prisesListindex += 1
          }
        }, 5000);
      }
    });

    articlearticleType({ platform: 3 }).then((res) => {

      this.news_title = res.data

      this.getnewslist(res.data[0].id)
    });

    this.imgload();
    window.addEventListener("resize", () => {
      if (this.$refs.heiimg[0]) {
        this.bennerheight = this.$refs.heiimg[0].height;
        this.imgload();
      }

    });

  },
};
</script>
<style scoped lang="scss">
.hdimgs_details {
  width: 100%;
  height: 100%;
  // overflow: hidden;

  img {
    vertical-align: top;
  }
}

.home {
  .headimg {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
}

.bennerbox {
  width: 100%;
  min-width: 1480px;
}

.threelunbo {
  // padding: 10px;
  // background-color: #f9f9fb;
  overflow: hidden;
  border-radius: 15px;
}

.threebox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  >div {
    background-color: #f9f9fb;
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex: 1;

    margin-right: 10px;

    padding: 10px;
    font-size: 15px;
    text-align: center;
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-right: 10px;
  }
}

.boxnews_title {
  .tit {
    font-size: 30px;
    color: #00a0e9;
  }

  .titflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .newsmennu {
    display: flex;

    >div {
      margin-left: 30px;
      font-size: 15px;
      cursor: pointer;
      color: #787878;
    }
  }

  .newsmennu_check {
    color: #00a0e9 !important;
  }
}

.boxtitlecontent {
  width: 80%;

  .tit {
    font-size: 30px;
    color: #00a0e9;
  }

  .titflex {
    display: flex;
    justify-content: space-between;
  }





  .cont {
    color: #a0a0a0;
    margin-top: 15px;
  }
}

.carbon_zc {
  padding: 30px;
  margin-top: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .carbon_zc_icon {
    width: 170px;
    margin-right: 40px;
  }

  .carbon_zc_content {
    width: 990px;
  }

  .carbon_zc_ic {
    width: 80px;
    text-align: center;
    font-size: 30px !important;
  }

  .carbon_zc_content_tit {
    font-size: 19px;
  }

  .carbon_zc_content_co {
    color: #a0a0a0;
    margin-top: 10px;
  }
}

.dibu {
  margin-top: 1000px;
}

.allbox {
  padding: 50px 0;
  min-width: 1130px;
}

.carbon_tjy {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  .back1 {
    background-color: white;
  }

  .back1 {
    background-color: white;
  }

  >div {
    width: 350px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 40px;

    img {
      width: 100%;

    }

    .carbon_tjy_content {
      padding: 15px;

      .carbon_tjy_tltle {
        font-size: 19px;
      }

      .carbon_tjy_cont {
        margin-top: 7px;
        color: #a0a0a0;
      }
    }
  }

  >div:last-child {
    margin-right: 0px;
  }
}

.flextwos {
  display: flex !important;
  align-items: center;
  justify-content: space-between;

  .fl1s_1 {
    width: 250px;
    margin-right: 20px;
  }

  .fl1s_2 img {
    width: 40px;
    height: 40px;
    vertical-align: top;
  }
}

.carbon_kj {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  >div {
    width: 565px;
    padding: 20px;
    border-radius: 7px;
    border: 1px solid #ebebeb;
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    .carbon_kj_icon {
      width: 70px;
      margin-right: 30px;
    }

    .carbon_kj_content {
      width: 385px;

      .carbon_kj_content_tit {
        font-size: 19px;
      }

      .carbon_kj_content_co {
        color: #a0a0a0;
        margin-top: 10px;
      }
    }
  }
}

.imgbtns {
  margin-left: 40px;
  font-size: 15px;
  position: relative;
  margin-top: -70px;
  color: #225b92;

  >div {
    cursor: pointer;
    transition-duration: 0.4s;
    display: inline-block;
    padding: 10px 15px;
    background-color: white;
    border-radius: 40px;
    margin-right: 20px;

  }

  >div:hover {
    background-color: #00a0e9;
    color: white;
  }
}

.newstext {
  background-color: #00000048;
  height: 30px;
  line-height: 30px;
  ;
  margin-top: -50px;
  position: relative;
  color: white;

  padding: 0 10px;
}

.indextit_flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.more {
  cursor: pointer;
  color: #9f9f9f
}

.carbonprice {
  background-color: #f9f9fb;
  padding: 20px;
  cursor: pointer;

  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  >div:first-child {

    // border-right: 1px solid #656565;
    padding: 0 10px;
  }

  .caprname {
    font-size: 20px;
    margin-right: 15px;
    color: #00a0e9;
  }

  .capr_pr {
    font-weight: bold;
    margin-right: 15px;
  }

  .capr_qj {
    color: #3f3f3f;
  }

  .car_jg {
    padding: 0 10px;

    >span:first-child {
      font-weight: bold;
    }

    >span {
      margin-right: 15px;
      color: #3f3f3f;
    }
  }
}

.mgtp2 {
  margin-top: 30px;
}

.colo {
  color: #67C23A !important;
}

.colo2 {
  color: red !important;
}

.laba {
  width: 20px;
  position: relative;
  top: 4px;

}

.ewmxz {
  width: 100px;
  // text-align: center;
  background-color: white;
  border: 2px solid #34c4f1;
  // color: white;
  padding: 5px;
  position: fixed;
  right: 10px;
  top: 70%;
  z-index: 9999;
  border-radius: 10px;

  .ic {
    text-align: right;
  }

  .sz {
    text-align: center;
    font-size: 13px;
  }

  img {
    // margin-left: 10px;
    width: 100px;
    height: 100px;
    // margin-bottom: 10px;
  }
}

.news_contentbox {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  >div {
    width: 600px;
  }
}

.news_left_box {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  width: 600px;
  height: 335px;

  .news_left_text {
    position: absolute;
    width: calc(100% - 20px);
    bottom: 0;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }
}

.news_right_box {


  >div {

    padding: 17px 0;
    border-bottom: 1px solid #e4e4e4;
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    >div:first-child {
      width: 480px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
      transition: all .3s;
      font-size: 15px;
    }

    >div:last-child {
      width: 90px;
      font-size: 14px;
      color: #8d8d8d;
    }
  }

  >div:last-child {
    border-bottom: none;
  }

  >div:hover>div:first-child {
    color: #00a0e9 !important;
  }
}


</style>