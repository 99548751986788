import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)
/**
 * meta ：
 * keepAlive:true : false 是否显示底部
 * type：头部导航栏高亮显示
 * 
 * 
 * 
 * 
 */
const routes = [{
    path: '/',
    name: 'home',
    component: index,
    meta: {
        keepAlive: true,
        type: '/'
    }
},
{
    path: '/carbonmanagement',
    name: 'carbonmanagement',
    component: () =>
        import('@/views/carbonmanagement/index.vue'),
    meta: {
        keepAlive: true,
        type: '/carbonmanagement'
    }
},
{
    path: '/loading',
    name: 'loading',
    component: () =>
        import('@/views/loading/loading.vue'),
    meta: {
        keepAlive: true,
        type: '999'
    }
},
{
    path: '/My',
    name: 'my',
    component: () =>
        import('@/views/my/my.vue'),
    meta: {
        keepAlive: true,
        type: '/My',
        bottomcolor: '#ffffff'

    }
},
{
    path: '/carbonecharts',
    name: 'carbonecharts',
    component: () =>
        import('@/views/carbonecharts/carbonecharts.vue'),
    meta: {
        keepAlive: true,
        type: '999'
    }
},
{
    path: '/carboncount',
    name: 'carboncount',
    component: () =>
        import('@/views/carboncount/carboncount.vue'),
    meta: {
        keepAlive: true,
        type: '/carboncount'
    }
},
{
    path: '/carbon_my',
    name: 'carbon_my',
    component: () =>
        import('@/views/carboncount/carbon_my.vue'),
    meta: {
        keepAlive: true,
        type: '/carboncount',
        bottomcolor: '#ffffff'
    }
},

{
    path: '/compute',
    name: 'compute',
    component: () =>
        import('@/views/carboncount/compute.vue'),
    meta: {
        keepAlive: true,
        type: '/carboncount'
    }
},

// 
{
    path: '/login',
    name: 'login',
    component: () =>
        import('@/views/login/login.vue'),
    meta: {
        keepAlive: false,
        type: '999'
    }
},
{
    path: '/register',
    name: 'register',
    component: () =>
        import('@/views/register/register.vue'),
    meta: {
        keepAlive: false,
        type: '999'
    }
},
{
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: () =>
        import('@/views/forgotpassword/forgotpassword.vue'),
    meta: {
        keepAlive: false,
        type: '999'
    }
},
{
    //寄存详情
    path: '/deposit_details',
    name: 'deposit_details',
    component: () =>
        import('@/views/carbonmanagement/deposit_details/deposit_details.vue'),
    meta: {
        keepAlive: true,
        type: '/carbonmanagement'
    }
},
{
    //寄存表格
    path: '/deposit_form',
    name: 'deposit_form',
    component: () =>
        import('@/views/carbonmanagement/deposit_form/deposit_form.vue'),
    meta: {
        keepAlive: true,
        type: '/carbonmanagement',
        bottomcolor: '#ffffff'
    }
},
{
    //托管详情
    path: '/trusteeship_details',
    name: 'trusteeship_details',
    component: () =>
        import('@/views/carbonmanagement/trusteeship_details/trusteeship_details.vue'),
    meta: {
        keepAlive: true,
        type: '/carbonmanagement'
    }
},
{
    //金融详情
    path: '/finance_details',
    name: 'finance_details',
    component: () =>
        import('@/views/carbonmanagement/finance_details/finance_details.vue'),
    meta: {
        keepAlive: true,
        type: '/carbonmanagement'
    }
},
{
    //申请信托，托管列表
    path: '/applylist',
    name: 'applylist',
    component: () =>
        import('@/views/carbonmanagement/applylist/applylist.vue'),
    meta: {
        keepAlive: true,
        type: '/carbonmanagement'
    }
},
{
    //otc
    path: '/OTC',
    name: 'OTC',
    component: () =>
        import('@/views/carbontrading/OTC.vue'),
    meta: {
        keepAlive: true,
        type: '/carbontrading'
    }
},
{
    //otc详细
    path: '/otcbuy',
    name: 'otcbuy',
    component: () =>
        import('@/views/carbontrading/otcbuy.vue'),
    meta: {
        keepAlive: true,
        type: '/carbontrading'
    }
},

{
    //撮合
    path: '/transaction',
    name: 'transaction',
    component: () =>
        import('@/views/carbontrading/transaction.vue'),
    meta: {
        keepAlive: true,
        type: '/carbontrading'
    }
}, {
    //撮合
    path: '/mydeal',
    name: 'mydeal',
    component: () =>
        import('@/views/carbontrading/mydeal.vue'),
    meta: {
        keepAlive: true,
        type: '/carbontrading'
    }
},
{
    //撮合出售
    path: '/sell',
    name: 'sell',
    component: () =>
        import('@/views/carbontrading/sell.vue'),
    meta: {
        keepAlive: true,
        type: '/carbontrading'
    }
},
{
    //撮合详细
    path: '/transactionbuy',
    name: 'transactionbuy',
    component: () =>
        import('@/views/carbontrading/transactionbuy.vue'),
    meta: {
        keepAlive: true,
        type: '/carbontrading'
    }
},
{
    //零碳家园
    path: '/zerocarbonhome',
    name: 'zerocarbonhome',
    component: () =>
        import('@/views/zerocarbonhome/zerocarbonhome.vue'),
    meta: {
        keepAlive: true,
        type: '/zerocarbonhome'
    }
},
// {
//     //零碳家园
//     path: '/meetingcount',
//     name: 'meetingcount',
//     component: () =>
//         import('@/views/zerocarbonhome/meetingcount.vue'),
//     meta: {
//         keepAlive: true,
//         type: '/zerocarbonhome'
//     }
// },
{
    //关于我们
    path: '/aboutus',
    name: 'aboutus',
    component: () =>
        import('@/views/aboutus/aboutus.vue'),
    meta: {
        keepAlive: true,
        type: '999'
    }
},
{
    //
    path: '/news',
    name: 'news',
    component: () =>
        import('@/views/news/news.vue'),
    meta: {
        keepAlive: true,
        type: '999'
    }
},
{
    //
    path: '/news_more',
    name: 'news_more',
    component: () =>
        import('@/views/news/news_more.vue'),
    meta: {
        keepAlive: true,
        type: '999'
    }
},


{
    //支付页面
    path: '/pay',
    name: 'pay',
    component: () =>
        import('@/views/pay/pay.vue'),
    meta: {
        keepAlive: true,
        type: '999',
        bottomcolor: '#ffffff'
    }
}, {
    //支付页面
    path: '/homepay',
    name: 'homepay',
    component: () =>
        import('@/views/pay/homepay.vue'),
    meta: {
        keepAlive: true,
        type: '999'
    }
}, {
    //支付成功页面
    path: '/paysuccess',
    name: 'paysuccess',
    component: () =>
        import('@/views/pay/paysuccess.vue'),
    meta: {
        keepAlive: true,
        type: '999',
        bottomcolor: '#ffffff'
    }
}, {
    //支付成功页面
    path: '/retire',
    name: 'retire',
    component: () =>
        import('@/views/retire/retire.vue'),
    meta: {
        keepAlive: true,
        type: '999',
        bottomcolor: '#ffffff'
    }
}, {
    //绿色碳汇
    path: '/green_carbon',
    name: 'green_carbon',
    component: () =>
        import('@/views/green_carbon/green_carbon.vue'),
    meta: {
        keepAlive: true,
        type: '/green_carbon',
        bottomcolor: '#ffffff'
    }
}, {
    //绿色碳汇
    path: '/green_details',
    name: 'green_details',
    component: () =>
        import('@/views/green_carbon/green_details.vue'),
    meta: {
        keepAlive: true,
        type: '/green_carbon',
        bottomcolor: '#ffffff'
    }
},{
    //绿色碳汇
    path: '/climate_school',
    name: 'climate_school',
    component: () =>
        import('@/views/climate_school/climate_school.vue'),
    meta: {
        keepAlive: true,
        type: '/climate_school',
        bottomcolor: '#ffffff'
    }
},{
    //零碳公民计算
    path: '/citizen',
    name: 'citizen',
    component: () =>
        import('@/views/zerocarbonaction/citizen/from.vue'),
    meta: {
        keepAlive: true,
        type: '/zerocarbonhome',
    }
},{
     //零碳公民计算 精算
    path: '/citizen_actuary',
    name: 'citizen_actuary',
    component: () =>
        import('@/views/zerocarbonaction/citizen/actuary.vue'),
    meta: {
        keepAlive: true,
        type: '/zerocarbonhome',
    }
},{
    //零碳公民计算 估算 
   path: '/citizen_estimate',
   name: 'citizen_estimate',
   component: () =>
       import('@/views/zerocarbonaction/citizen/estimate.vue'),
   meta: {
       keepAlive: true,
       type: '/zerocarbonhome',
   }
}
,{
    //零碳公民计算
    path: '/meetingcount',
    name: 'meetingcount',
    component: () =>
        import('@/views/zerocarbonaction/meetingcount/from.vue'),
    meta: {
        keepAlive: true,
        type: '/zerocarbonhome',
    }
},{
     //零碳公民计算 精算
    path: '/meeting_actuary',
    name: 'meeting_actuary',
    component: () =>
        import('@/views/zerocarbonaction/meetingcount/actuary.vue'),
    meta: {
        keepAlive: true,
        type: '/zerocarbonhome',
    }
},{
    //零碳公民计算 估算 
   path: '/meeting_estimate',
   name: 'meeting_estimate',
   component: () =>
       import('@/views/zerocarbonaction/meetingcount/estimate.vue'),
   meta: {
       keepAlive: true,
       type: '/zerocarbonhome',
   }
},{
    //下载app
    path: '/downloadapp',
    name: 'downloadapp',
    component: () =>
        import ('@/views/downloadapp/downloadapp.vue'),
    meta: {
        keepAlive: false,
        type: '/Emissionreductionactions'
    }
},,{
    //零碳企业学校类型选择
   path: '/carbonschool',
   name: 'carbonschool',
   component: () =>
       import('@/views/zerocarbonaction/carbonschool/type.vue'),
   meta: {
       keepAlive: true,
       type: '/zerocarbonhome',
   }
},{
    //零碳企业填表
   path: '/carbonenterprise_form',
   name: 'carbonenterprise_form',
   component: () =>
       import('@/views/zerocarbonaction/carbonschool/carbonenterprise_form/form.vue'),
   meta: {
       keepAlive: true,
       type: '/zerocarbonhome',
   }
},{
    //零碳企业 精算
   path: '/carbonenterprise_form_actuary',
   name: 'carbonenterprise_form_actuary',
   component: () =>
       import('@/views/zerocarbonaction/carbonschool/carbonenterprise_form/actuary.vue'),
   meta: {
       keepAlive: true,
       type: '/zerocarbonhome',
   }
},{
    //零碳学校填表
   path: '/carbonschool_form',
   name: 'carbonschool_form',
   component: () =>
       import('@/views/zerocarbonaction/carbonschool/carbonschool_form/form.vue'),
   meta: {
       keepAlive: true,
       type: '/zerocarbonhome',
   }
},{
    //零碳学校填表
   path: '/carbonschool_form_actuary',
   name: 'carbonschool_form_actuary',
   component: () =>
       import('@/views/zerocarbonaction/carbonschool/carbonschool_form/actuary.vue'),
   meta: {
       keepAlive: true,
       type: '/zerocarbonhome',
   }
}
,{
    //碳资产开发
   path: '/carbondevelop',
   name: 'carbondevelop',
   component: () =>
       import('@/views/carbondevelop/index.vue'),
   meta: {
       keepAlive: true,
       type: '/carbondevelop',
   }
},{
    //碳资产开发 项目案例
   path: '/carboncase',
   name: 'carboncase',
   component: () =>
       import('@/views/carbondevelop/case.vue'),
   meta: {
       keepAlive: true,
       type: '/carbondevelop',
   }
},{
    //碳资产开发 开发类型
   path: '/carbondevetype',
   name: 'devetyppe',
   component: () =>
       import('@/views/carbondevelop/devetype.vue'),
   meta: {
       keepAlive: true,
       type: '/carbondevelop',
   }
},{
    //碳资产开发 权威专家
   path: '/expert',
   name: 'expert',
   component: () =>
       import('@/views/carbondevelop/expert.vue'),
   meta: {
       keepAlive: true,
       type: '/carbondevelop',
   }
},







]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router