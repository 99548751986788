import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import language from '@/language/index'
import locale from 'element-ui/lib/locale/lang/en'
import VueClipBoard from 'vue-clipboard2'
import money_count from "@/requrest/money.js"
import { countryExchangeRate } from "@/api/newapi.js"

// import echarts from "echarts";
// let echarts = require('echarts')

// Vue.prototype.$echarts = echarts;


Vue.use(VueClipBoard)

Vue.config.productionTip = false
Vue.prototype.$lg = language.toggle()



var languagetype = localStorage.getItem('language');
if (languagetype == 'en') {
    Vue.use(ElementUI, { locale })
} else {
    Vue.use(ElementUI);
}


const tolink = (url, type) => {

    // type 1 需要登陆 2 不需要登陆
    if (type == 1) {
        if (localStorage.getItem('token')) {
            router.push(url);
        } else {
            router.push('/login');
        }
    } else {
        router.push(url);
    }
}

const building = () => {
    Message({
        message: language.toggle().building,
        duration: 3000
    })

}

const exchange = (money,type) => {
    let money_ = parseFloat(money)
    var exchangedata = localStorage.getItem('exchange')
    // unit 单位
    // rate 汇率
    if (!exchangedata) {
        countryExchangeRate({ id: 1 }).then(res => {
            exchangedata = JSON.stringify(res.data)
            localStorage.setItem('exchange', exchangedata)
            exchangedata = JSON.parse(exchangedata)
            let exmoney_ = parseFloat(exchangedata.rate)
            let count_ = parseInt(money_ * exmoney_ * 1000) / 1000
            // Math.ceil() 向上取整
            if (type == 2){
                return  Math.ceil(count_ * 100) / 100
            }else if (type == 3){
                return Math.ceil((money / exmoney_) * 100) / 100 
            }else{
                return  exchangedata.unit+Math.ceil(count_ * 100) / 100
            }
            
        })
    } else {
        
        exchangedata = JSON.parse(exchangedata)
        let exmoney_ = parseFloat(exchangedata.rate)
        let count_ = parseInt(money_ * exmoney_ * 1000) / 1000
        // Math.ceil() 向上取整
        if (type == 2){
            return  Math.ceil(count_ * 100) / 100
        }else if (type == 3){
            return Math.ceil((money / exmoney_) * 100) / 100 
        }else{
            return  exchangedata.unit+Math.ceil(count_ * 100) / 100
        }

    }

}

Vue.prototype.$carbon = {
    tolink,
    // baseurl: 'https://dev.zerocarbonex.com',
    baseurl: 'https://api.zerocarbonex.com',
    // baseurl: 'http://192.168.88.169',
    building,
    version: 2, // 1 英文版，2 气候商店，3 印尼版 
    version2: 2, // 1 气候商店，2 英文版 ：交易系统
    exchange
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')